import { Component, OnInit } from '@angular/core';
import { CustomerService } from '../customer.service';
import {Customer} from '../models/customer';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.css']
})
export class CustomerComponent implements OnInit {

  customer: Customer;
  constructor( private customerService: CustomerService ) {}

  ngOnInit(): void {
    this.customerService.getCustomers()
      .subscribe(
        (data) => {
          this.customer = data;
        },
        (error) => {
          console.log(error);
        });
  }

  showConfig() {
    this.customerService.getCustomers()
      .subscribe(
        (data) => {
          this.customer = data;
        },
        (error) => {
          console.log(error);
        });
  }
}
