import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import {Customer} from './models/customer';

@Injectable({ providedIn: 'root' })
export class CustomerService {

  options: {
    headers?: HttpHeaders | {[header: string]: string | string[]},
    observe?: 'body' | 'events' | 'response',
    params?: HttpParams|{[param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>},
    reportProgress?: boolean,
    responseType?: 'arraybuffer'|'blob'|'json'|'text',
    withCredentials?: boolean,
  };

  customerUrl = '/customers';

  constructor( private http: HttpClient ) { }

  getCustomers() {
    return this.http.get<Customer>(this.customerUrl);
  }
}
